<template>
  <div>
    <Navbar />
    <div class="containerDistributions">
      <div class="photoSide">
        <iframe
          style="
            margin: auto;

            justify-content: center;
            display: flex;

            align-items: center;
          "
          v-if="leftSideContent.video"
          width="100%"
          height="100%"
          :src="leftSideContent.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="contentSide" v-else>
          <lingallery
            :id.sync="leftSideContent.id"
            :width="650"
            :items="lingalerryItems"
            :height="400"
          >
          </lingallery>
        </div>
      </div>
      <div class="textSide">
        <div class="valuesList pl-5">
          <div class="accordion col" role="tablist">
            <div class="row pl-0">
              <div
                class="col-12 col-lg-12 pl-0 pr-0"
                v-for="(item, index) in data"
                :key="index"
              >
                <b-card no-body class="mb-1 pl-0 pr-0">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button
                      block
                      :v-b-toggle="`accordion-${index}`"
                      variant="info"
                      class="p-0"
                    >
                      <div
                        class="d-flex tabsIcons p-0"
                        @click="switchImage(index)"
                      >
                        <div>
                          <img
                            class="Icons"
                            :src="`${server_url}/${item.icon}`"
                            :alt="
                              lang == 'ka'
                                ? removeHTML(item.titleKA)
                                : removeHTML(item.titleEN)
                            "
                          />
                        </div>
                        <div>
                          <p class="blackColor text-left titleP pl-3 mt-3">
                            <span
                              v-if="lang == 'ka'"
                              v-html="item.titleKA"
                            ></span>
                            <span v-else v-html="item.titleEN"></span>
                          </p>
                        </div>
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    :id="`accordion-${index}`"
                    :visible="visibleIndex === index ? true : false"
                    :accordion="`menu-accordion${index}`"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text
                        ><span
                          v-if="lang == 'ka'"
                          v-html="item.descriptionKA"
                        ></span>
                        <span v-else v-html="item.descriptionEN"></span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";

import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      data: {},
      image: null,
      leftSideContent: {},
      visibleIndex: 0,
      server_url: env.server_url,
      data: [],
      lang: "ka",
      lingalerryItems: [],
    };
  },
  mounted() {
    axios
      .get(`${env.host}/get/informations/distribution`)
      .then((result) => {
        this.leftSideContent = result.data.item[this.visibleIndex];
        this.lingalerryItems = this.leftSideContent.images.map((obj) => ({
          src: `${this.server_url}/${obj.image}`,
          thumbnail: `${this.server_url}/${obj.image}`,
        }));
        this.data = result.data.item;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    switchImage(index) {
      this.visibleIndex = index;

      axios
        .get(`${env.host}/get/informations/distribution`)
        .then((result) => {
          console.log(result);
          this.leftSideContent = result.data.item[index];
        })

        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.photoSide {
  justify-content: center;

  width: 100%;
  padding: 0px;
  align-items: center;
}

.contentSide {
  width: 100%;
  max-width: 100%;
}
.Icons {
  align-items: center;
}

::v-deep .lingallery {
  width: 100% !important;

  height: 100% !important;
}

.lingalleryContainer
  .lingallery
  .lingallery_thumbnails
  .lingallery_thumbnails_content {
  margin-top: 2px;
  width: 100%;

  white-space: nowrap;
}

::v-deep .lingallery figure img {
  width: 100%;
  height: 100%;
}
::v-deep .lingallery figure {
  width: 100% !important;
  max-width: 100%;
}

::v-deep .lingallery img {
  object-fit: cover;
  width: 100%;
  background-size: cover;
  justify-content: center center;
}

.containerDistributions {
  width: 100%;

  margin: auto;
  padding: 0px;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 190px);
}

.textSide {
  display: flex;

  padding: 40px;
}
.valuesList {
  width: 100%;

  padding: 0px;
  margin: 0px;
}

.card-body {
  padding-right: 0px;
  padding-top: 0px;
}

.titleP {
  width: 300px;
}

.accordion img {
  width: 40px;
}
::v-deep .btn-info {
  background-color: white;
  border-color: white;
}

::v-deep .btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
}

::v-deep .card {
  border: none !important;
}

::v-deep .btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

::v-deep .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

::v-deep .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  display: flex;

  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.tabsIcons {
  width: 50px;
}

@media all and (max-width: 1500px) {
  .valuesList {
    padding-left: 0px !important;
    padding-right: 0px !important ;
  }
}

@media all and (max-width: 992px) {
  .valuesList {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and (max-width: 992px) {
  .containerDistributions {
    grid-template-columns: 1fr;
  }

  iframe {
    height: 400px !important;
    padding-left: 0px;
  }

  .textSide {
    padding: 0px;
  }
}

@media all and (max-width: 576px) {
  .titleP {
    width: auto;
  }
}

@media all and (max-width: 455px) {
  .photoSide {
    max-width: 100%;
  }
}

@media all and (max-width: 1500px) {
  .textSide {
    padding: 20px !important;
  }
}
@media all and (max-width: 992px) {
  .textSide {
    padding: 0px;
  }
}
</style>